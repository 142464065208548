var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _vm._m(0),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "showcase-intro" },
      [
        _c("h1", { staticClass: "title" }, [
          _vm._v("\n      Tell our story in a Showcase\n    "),
        ]),
        _vm._v(" "),
        _c("p", { staticClass: "subtitle" }, [
          _vm._v(
            "\n      A showcase tells a story. And just as all stories they are best told\n      brief and informative at the same time. Remember that your audience is\n      familiar with NN so you don't need to explain everything in detail.\n      International differences may ask for some additional information\n      though.\n    "
          ),
        ]),
        _vm._v(" "),
        _c(
          "router-link",
          {
            staticClass: "btn primary",
            attrs: {
              to: _vm.$generateRoute({
                name: "showcase.submit",
                params: {
                  showcaseId: _vm.showcaseId,
                },
              }),
            },
          },
          [_vm._v("\n      Submit your work\n    ")]
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "header",
      {
        staticClass: "page-region",
        attrs: { id: "page-primary-banner", "aria-label": "Page Banner Area" },
      },
      [
        _c(
          "div",
          {
            staticClass: "interior-page-banner-container full-width",
            attrs: { id: "interior-page-banner-container" },
          },
          [
            _c(
              "div",
              {
                staticClass: "interior-page-header is-admin",
                attrs: { id: "interior-banner", role: "banner" },
              },
              [
                _c("div", { staticClass: "inner" }, [
                  _c("div", { staticClass: "header-items" }, [
                    _c("h1", { staticClass: "title" }, [
                      _vm._v("\n              Showcase\n            "),
                    ]),
                  ]),
                ]),
              ]
            ),
          ]
        ),
      ]
    )
  },
]
render._withStripped = true

export { render, staticRenderFns }